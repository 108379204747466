.galleryArea {
  background-color: #e4e0dd;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 35%;
    width: 100%;
    background-color: $gray-200;
    z-index: 1;
  }
  .brandContainer {
    position: relative;
    z-index: 2;
  }
}
