.conceptArea {
  background-color: $gray-200;
  .brandContainer {
    display: grid;
    grid-template:
      "title ... img" auto
      ".... ... ...." 20px
      "text text text " auto
      / auto 1fr auto;
    @include media-breakpoint-up(sm) {
      grid-template:
        "title ... ..." auto
        ".... ... ...." 65px
        "text ... img" auto
        / 1fr 40px 200px;
    }
    @include media-breakpoint-up(lg) {
      grid-template:
        "title ... ..." auto
        ".... ... ...." 65px
        "text ... img" auto
        / 1fr 80px auto;
    }
    .sectionHeader {
      grid-area: title;
      margin-bottom: 0;
    }
    .articleBody {
      margin: 0;
      grid-area: text;
      max-width: none;
    }
    .img {
      grid-area: img;
      mix-blend-mode: multiply;
      @include media-breakpoint-up(lg) {
        margin-top: -30px;
      }

      img {
        width: auto;
        height: 80px;
        @include media-breakpoint-up(sm) {
          height: auto;
        }
      }
    }
  }
}
