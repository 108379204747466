.toggleMenu {
  position: fixed;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: $zindex-fixed;
  min-width: $headerHeight;
  min-height: $headerHeight;
  @include media-breakpoint-up(sm) {
    min-width: $headerHeightSM;
    min-height: $headerHeightSM;
    display: none;
  }
  .toggleMenuBtn {
    position: absolute;
    z-index: 2;
    right: 0;
    top: 0;
    width: $headerHeight;
    height: $headerHeight;
    display: flex;
    gap: 8%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $body-color;
    @include media-breakpoint-up(sm) {
      width: $headerHeightSM;
      height: $headerHeightSM;
    }
    cursor: pointer;
    .bar,
    &::before,
    &::after {
      width: 36%;
      height: 1px;
      background-color: $white;
      content: "";
      transition: $transition-base;
    }
    &::after {
      transform: scaleX(60%);
      transform-origin: left;
    }
    &:hover {
      &::after {
        transform: scaleX(100%);
      }
    }
    &.active {
      .bar {
        opacity: 0;
        display: none;
        position: absolute;
      }
      &::before {
        position: absolute;
        top: 50%;
        left: 32%;
        transform: rotate(45deg);
        transform-origin: center;
      }
      &::after {
        position: absolute;
        top: 50%;
        right: 32%;
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
  .cont {
    padding-top: $headerHeight;
    background-color: $body-color;
    color: $white;
    position: relative;
    z-index: 1;
    width: 600px;
    height: 100vh;
    max-width: 100vw;
    overflow-y: auto;
    @include media-breakpoint-up(sm) {
      padding-top: $headerHeightSM;
    }
    .gnav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      > li {
        > a {
          color: $white;
          text-decoration: none;
          display: flex;
          padding: 1rem 2rem;
        }
        > ul {
          margin: 0;
          padding: 0;
          list-style-type: none;
          background-color: tint-color($body-color, 5%);
          font-size: 90%;
          border-left: 1rem solid $body-color;
          > li {
            > a {
              color: $white;
              text-decoration: none;
              display: flex;
              padding: 1rem 2rem;
            }
          }
        }
      }
    }
  }
}
