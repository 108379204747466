.hero {
  width: 100vw;
  height: 160vw;
  position: relative;
  margin-top: $headerHeight * -1;
  @include media-breakpoint-up(sm) {
    margin-top: $headerHeightSM * -1;
    height: 100vh;
  }
  @include media-breakpoint-up(lg) {
    height: 100vh;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .topNews {
    z-index: 3;
    position: absolute;
    bottom: 15px;
    left: 0;
    max-width: calc(100vw - 60px);
    min-width: 200px;
    @include media-breakpoint-up(sm) {
      bottom: 30px;
      min-width: 300px;
    }
  }
  .cont {
    width: 100%;
    height: 100%;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: $white;
    padding: $headerHeight * 1.5 6vw;
    @include media-breakpoint-up(sm) {
      padding: $headerHeight * 3 6vw;
    }
    @include media-breakpoint-up(lg) {
      justify-content: center;
      padding: 6vw;
    }
    .catchCopy {
      @include fontSize(18);
      margin: 0;
      line-height: 1.5;
      letter-spacing: 0.1em;
      @include media-breakpoint-up(sm) {
        @include fontSizeSM(21);
      }
      @include media-breakpoint-up(lg) {
        @include fontSizeLG(21);
        letter-spacing: 0.2em;
      }
    }
  }
  .scrollIcon {
    padding-right: 4em;
    display: inline-block;
    color: $white;
    border-bottom: 1px solid $white;
    position: absolute;
    z-index: 3;
    bottom: 0;
    right: 30px;
    font-size: 10px;
    letter-spacing: 0.5em;
    text-transform: uppercase;
    transform-origin: right bottom;
    transform: rotate(90deg);
    @include media-breakpoint-up(sm) {
      right: 45px;
    }
  }
}
