.widelayout {
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
}
.brandContainer {
  padding: 0 6vw;
}
.brandSpace {
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 120px;
  }
}
.contentsContainer {
  max-width: calc(800px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
}
.magazineCont {
  max-width: 1200px;
  margin: 0 auto;
  .sideCont {
    margin-top: 60px;
    @include media-breakpoint-up(sm) {
      margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .mainCont {
      width: 800px;
    }
    .sideCont {
      width: 320px;
    }
  }
}
.bg-gray {
  background-color: $gray-200;
}
.bg-darkgray {
  background-color: #e4e0dd;
}
