$headerHeight: 80px;
$headerHeightSM: 105px;
#header {
  width: 100%;
  height: $headerHeight;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  @include media-breakpoint-up(sm) {
    height: $headerHeightSM;
    padding: 0 30px;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 45px;
  }
  .logo {
    a {
      svg {
        fill: $body-color;
        height: 36px;
        @include media-breakpoint-up(sm) {
          height: 45px;
        }
      }
    }
  }
  .gnav {
    font-size: 13px;
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1.5em;
      li {
        a {
          color: $body-color;
          text-decoration: none;
          transition: $transition-base;
          &:hover {
            color: $black;
          }
        }
      }
    }
  }
  &.top:not(.scrolled) {
    .logo {
      a {
        svg {
          fill: $body-bg;
        }
      }
    }
    .gnav {
      ul {
        li {
          a {
            color: $body-bg;
            &:hover {
              color: $body-bg;
            }
          }
        }
      }
    }
  }
}
