.gallery {
  display: grid;
  grid-template:
    "img01 img01 img01 ... img02" auto
    "img01 img01 img01 ... img02" 5px
    "img01 img01 img01 ... img02" auto
    "..... ..... ..... ... ....." 5px
    "img03 ..... img04 ... img05" auto
    / 1fr 5px 1fr 5px 1fr;
  @include media-breakpoint-up(sm) {
    grid-template:
      "img01 img01 img01 ... img02" auto
      "img01 img01 img01 ... img02" 10px
      "img01 img01 img01 ... img02" auto
      "..... ..... ..... ... ....." 10px
      "img03 ..... img04 ... img05" auto
      / 1fr 10px 1fr 10px 1fr;
  }

  .img01 {
    grid-area: img01;
  }
  .img02 {
    grid-area: img02;
  }
  .img03 {
    grid-area: img03;
  }
  .img04 {
    grid-area: img04;
  }
  .img05 {
    grid-area: img05;
  }
  .img {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
