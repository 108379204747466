.informationArea {
  background-color: $gray-200;
  .body {
    @include media-breakpoint-up(sm) {
      display: flex;
    }
    .img {
      img {
        @include media-breakpoint-up(sm) {
          width: 350px;
        }
        @include media-breakpoint-up(lg) {
          width: 100%;
        }
      }
    }
    .detail {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding-top: 30px;
      @include media-breakpoint-up(sm) {
        padding-left: 20px;
        padding-top: 0;
      }
      @include media-breakpoint-up(lg) {
        padding-left: 60px;
      }
      .logo {
        padding-bottom: 30px;

        svg {
          width: 277px;
          fill: $body-color;
        }
      }
      .text {
        line-height: 2;
      }
    }
  }
}
