.articleHeader {
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 36px;
  padding: 45px 0;
  border-top: 1px dotted $body-color;
  border-bottom: 1px dotted $body-color;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
  }
  .title {
    font-size: 30px;
    a {
      color: $body-color;
      text-decoration: none;
      &:hover {
        color: $primary;
      }
    }
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }
  .data {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    .post-categories {
      display: flex;
      align-items: center;
      list-style-type: none;
      margin: 0;
      padding: 0;
      font-size: 15px;
      li {
        margin-right: 1rem;
        a {
          background-color: $white;
          color: $body-color;
          text-decoration: none;
          transition: $transition-base;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            color: $white;
            background-color: $secondary;
          }
        }
      }
    }
    .date {
      margin-right: 1rem;
      color: $body-color;
      font-size: 12px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
}
.articleBody {
  max-width: 800px;
  margin: 0 auto;
  $cms-space: 30px;
  font-size: 15px;
  padding-bottom: $cms-space * 2;
  .leadArea {
    font-size: 18px;
  }
  .tags {
    margin-bottom: $cms-space;
    color: $secondary;
    display: flex;
    justify-content: flex-start;
    a {
      display: flex;
      color: $secondary;
      text-decoration: none;
      transition: $transition-base;
      margin-right: 15px;
      &::before {
        content: "#";
      }
    }
  }
  img {
    max-width: 100%;
    height: auto;
  }

  p {
    margin-bottom: $cms-space;
    line-height: 2;
  }
  ul,
  ol {
    margin-bottom: $cms-space;
    line-height: 1.75;
  }
}
.namebox {
  .ownericon {
    text-align: center;
  }
  .info {
    text-align: center;
    .position {
      font-size: 15px;
      margin-bottom: 0;
      padding-top: 10px;
    }
    .name {
      font-size: 21px;
      line-height: 1.5;
    }
  }
}
