#footer {
  background-color: $gray-100;
  padding: 60px 6vw 105px 6vw;
  .header {
    p {
      font-size: 15px;
      text-align: center;
    }
    .instaicon {
      text-align: center;
    }
  }
  .copyright {
    font-size: 10px;
    text-align: center;
    padding-top: 60px;
  }
}
