.topNews {
  margin: 0;
  padding: 1rem 2rem;
  list-style: none;
  background-color: rgba($color: $black, $alpha: 0.8);
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 12px;
  @include media-breakpoint-up(sm) {
    padding: 1rem 2rem;
  }
  @include media-breakpoint-up(lg) {
    padding: 1rem 3rem;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    &::before {
      background-color: $white;
      width: 6px;
      height: 1px;
      content: "";
    }
    a {
      color: $white;
    }
  }
}
