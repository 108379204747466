/*----------------------------------------------------
pagination
----------------------------------------------------*/
.pagination {
  margin: 2rem 0;
  .screen-reader-text {
    display: none;
  }
  .nav-links {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 5px;
    a {
      color: $body-color;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $body-color;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      text-decoration: none;
      transition: $transition-base;
      &:hover {
        color: $white;
        background-color: $primary;
      }
    }
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      background-color: $body-color;
      font-weight: bold;
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }
}
